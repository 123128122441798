import { EnvironmentAPI } from "api/EnvironmentAPI";
import { BaseStorage } from "storage/BaseStorage";
import { Brand } from "./Brand";

const holding_key = 'of-holding';

export class Holding {

    static set alias(alias) {
        return localStorage.setItem(holding_key, alias);
    }

    static get alias() {
        return localStorage.getItem(holding_key);
    }

    static getHolding() {
        try {
            const holding = localStorage.getItem(Holding.alias);
            return holding ? JSON.parse(holding) : null;
        } catch (err) {
            return null;
        }
    }

    static async install(alias) {
        const envConfigurations = await EnvironmentAPI.getS3Configurations(alias);
        const baseStorage = new BaseStorage({ key: alias });
        if (envConfigurations.keys?.customer_api_key) {
            localStorage.setItem('customerApiKey', envConfigurations.keys.customer_api_key);
        }

        baseStorage.setData(envConfigurations);
        if (envConfigurations.brands) {
            await Promise.all(
                envConfigurations.brands.map(async (b) => {
                    return await Brand.install(b.alias)
                })
            );
        }
    }

    static get id () {
        const holding = Holding.getHolding();
        return holding?.id;
    }

    static get name() {
        const holding = Holding.getHolding();
        return holding?.name;
    }

    static get avatar() {
        const holding = Holding.getHolding();
        const avatar = holding?.avatar || "";

        if (avatar.startsWith("http")) {
            return avatar;
        } else if (!avatar) {
            return null
        }

        return `${process.env.NEXT_PUBLIC_CDN_ASSETS}/${avatar}`;
    }

    static get helpdesk_contact() {
        const holding = Holding.getHolding();
        return holding?.helpdesk_contact || {};
    }

    static get theme() {
        const holding = Holding.getHolding();
        return holding?.front_theme_super_app || {}
    }

    static getMobileBanners () {
        const theme = Holding.theme;
        const banners = theme?.covers?.mobile || [];
        return banners;
    }

    static getDesktopBanners () {
        const theme = Holding.theme;
        const banners = theme?.covers?.desktop || [];
        return banners;
    }
}