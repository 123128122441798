import { useEffect, useState } from "react";

import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";

import classNames from "classnames/bind";

import styles from "styles/layout/menu-mobile.module.scss";

let css = classNames.bind(styles);

import {
  TableRegular
} from "@/shared/components/Icons";

import { OrderAPI } from "api/OrderAPI";

import Cart from "@/shared/containers/Cart/Cart";

import { Drawer } from "@/shared/components";
import { Brand } from "@/shared/core/Brand";
import { Customer } from "@/shared/core/Customer";
import { Store } from "@/shared/core/Store";
import { OlgaFoodSession } from "@/shared/tools/OlgaFoodSession";

import { OpenTableAPI } from "@/api/OpenTableAPI";
import { Button } from "@/shared/components";
import {
  ForkKnifeRegular
} from "@/shared/components/Icons";
import Profile from "@/shared/containers/Account/Profile";
import CartReceiveOn from "@/shared/containers/Cart/CartReceiveOn";
import CartResume from "@/shared/containers/Cart/CartResume";
import ReceiveOnLocal from "@/shared/containers/Home/ReceiveOnLocal";
import OpenTable from "@/shared/containers/Order/OpenTable";
import { DataLayer } from "@/shared/tools/DataLayer";
import { OrderStorage } from "@/storage/OrderStorage";

import { Flows } from "@/shared/core/Enum";
import { Table as TableCore } from "@/shared/core/Table";

const Addressess = dynamic(() =>
  import("@/shared/containers/Address/Addressess")
);
const PaymentMethods = dynamic(() =>
  import("@/shared/containers/Payment/PaymentMethods")
);
const Club = dynamic(() => import("@/shared/containers/Benefits/Club"));
const OrderDetails = dynamic(() =>
  import("@/shared/containers/Order/OrderDetails")
);
const OrderTracking = dynamic(() =>
  import("@/shared/containers/Order/OrderTracking")
);
const LoginOrRegister = dynamic(() =>
  import("@/shared/containers/Account/LoginOrRegister")
);

const OrderStatus = {
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
  WAITING_PAYMENT: "WAITING_PAYMENT",
  OPENED: "OPENED",
  IN_PRODUCTION: "IN_PRODUCTION",
  ON_DELIVERY: "ON_DELIVERY",
  FINISHED: "FINISHED",
};

export default function MenuMobile() {
  const router = useRouter();

  const [openLoginOrRegister, setOpenLoginOrRegister] = useState(false);
  const [customerName, setCustomerName] = useState(null);
  const [openedOrders, setOpenedOrders] = useState([]);
  const [polling, setPolling] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openAddressess, setOpenAddressess] = useState(false);
  const [openBenefits, setOpenBenefits] = useState(false);
  const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [openOrderTracking, setOpenOrderTracking] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openCommand, setOpenCommand] = useState(false);
  const [commandOpened, setCommandOpened] = useState(false);
  const [openReceiveOn, setOpenReceiveOn] = useState(false);
  const [openClub, setOpenClub] = useState(false);
  const [table, setTable] = useState([]);
  const [quantityItemsTable, setQuantityItemsTable] = useState(null);
  const [openConfirmBackToHome, setOpenConfirmBackToHome] = useState(false);
  const [isDigitalMenu, setIsDigitalMenu] = useState(false);

  const onCustomerChanged = async () => {
    loadUser();
  };

  useEffect(() => {
    onCustomerChanged();
    Customer.onChange(onCustomerChanged);
    return () => Customer.offChange(onCustomerChanged);
  }, []);

  useEffect(() => {
    loadUser();
    setPolling(true);
  }, []);

  const loadUser = () => {
    setOpenLoginOrRegister(false);

    if (OlgaFoodSession.hasSession()) {
      setIsLogged(true);
      setCustomerName(Customer.name);
    } else {
      setIsLogged(false);
      setCustomerName(null);
    }
  };

  useEffect(() => {
    if (polling) {
      const timer = setInterval(() => {
        loadOpenedOrders();
      }, 10000);
      return () => clearInterval(timer);
    }
  }, [polling]);

  const setOrder = (order) => {
    setSelectedOrder(order);
    setOpenOrderDetails(true);
  };

  const ordersInProgress = () => {
    return openedOrders.filter((order) => {
      return [
        OrderStatus.OPENED,
        OrderStatus.IN_PRODUCTION,
        OrderStatus.ON_DELIVERY,
        OrderStatus.WAITING_PAYMENT,
      ].includes(order.status);
    });
  };

  const loadOpenedOrders = async () => {
    const ordersResponse = await OrderAPI.getOrders(0, 30);
    if (ordersResponse.getErrors([]).length === 0) {
      const newItems = ordersResponse.getData({ items: [] }).items;
      setOpenedOrders(newItems);
    } else {
      setPolling(false);
    }
  };

  const orderFlow = OrderStorage.flow;

  const loadTableItems = async () => {
    const environmentId = Store.id;
    const tableId = TableCore.id;

    const tableDetailsReq = await OpenTableAPI.getTableDetails(
      environmentId,
      tableId
    );
    const tableDetails = tableDetailsReq.getData({});
    setTable(tableDetails);
  };

  useEffect(() => {
    const externalId = TableCore.externalId;
    if (externalId === TableCore.isDigitalMenu) setIsDigitalMenu(true);
    else setIsDigitalMenu(false);

    loadTableItems();
  }, []);

  const backToHome = () => {
    OrderStorage.clearCartItems();
    setOpenConfirmBackToHome(false);

    router.push("/");
  };

  useEffect(() => {
    if (table?.produtos?.length > 0) {
      const groupedCombos = table.produtos.reduce(
        (result, item) => ({
          ...result,
          [item.cod_reg]: [...(result[item.cod_reg] || []), item],
        }),
        {}
      );

      setQuantityItemsTable(Object.keys(groupedCombos)?.length);
    }
  }, [table]);

  return (
    <>
      {!isDigitalMenu ? (
        <div className={styles.menuMobile} id="menuMobile">
          <CartResume
            openCart={() => {
              const order = OrderStorage.getOrder();

              const cartDataLayer = {
                event: "view_cart",
                ecommerce: {
                  currency: "BRL",
                  value: order?.total,
                  items: DataLayer.getOrderItems(order.cart?.catalog?.items),
                },
              };

              DataLayer.push({ ecommerce: null });
              DataLayer.push(cartDataLayer);

              setOpenCart(true);
            }}
          />

          {/* {(router.asPath === `/${Brand.alias}/cardapio` ||
          router.pathname === "/cardapio") &&
        !commandOpened &&
        orderFlow === Flows.OPEN_TABLE ? (
          <Button
            design="primary"
            onClick={() => setOpenCommand(true)}
            className="mb-15"
          >
            Abrir mesa
          </Button>
        ) : null} */}

          <ul>
            <Link
              href={
                Brand.alias
                  ? `/${Brand.alias}/cardapio`
                  : "/cardapio"
              }
            >
              <li
                className={css({
                  item: true,
                  active:
                    router.asPath === `/${Brand.alias}/cardapio` ||
                    router.asPath === `/${Brand.alias}` ||
                    router.pathname === "/cardapio" ||
                    router.pathname === `/${Brand.alias}`,
                })}
              >
                <span className={styles.icon}>
                  <ForkKnifeRegular />
                </span>
                <p className={styles.name}>Cardápio</p>
              </li>
            </Link>

            {orderFlow === Flows.OPEN_TABLE && (
              <Link
                href={
                  Brand.alias ? `/${Brand.alias}/mesa` : "/mesa"
                }
              >
                <li
                  className={css({
                    item: true,
                    active:
                    router.asPath === `/${Brand.alias}/mesa` ||
                    router.asPath === `/${Brand.alias}` ||
                    router.pathname === "/mesa" ||
                    router.pathname === `/${Brand.alias}`,
                  })}
                >
                  <span className={styles.icon}>
                    <TableRegular />
                    {quantityItemsTable ? (
                      <span className={styles.quantity}>
                        {quantityItemsTable}
                      </span>
                    ) : null}
                  </span>
                  <p className={styles.name}>Mesa</p>
                </li>
              </Link>
            )}

            {/* <li onClick={() => setOpenConfirmBackToHome(true)}>
              <span className={styles.icon}>
                <Reload />
              </span>
              <p className={styles.name}>Trocar mesa</p>
            </li> */}
          </ul>
        </div>
      ) : null}

      {orderFlow === Flows.DELIVERY && (
        <Cart
          open={openCart}
          onClose={() => {
            setOpenCart(false);
            const newPath = Brand.alias
              ? `/${Brand.alias}/cardapio`
              : "/cardapio";
            router.push(newPath);
          }}
          setOpenBenefits={() => setOpenBenefits(true)}
          selectDeliveryAddress={() => setOpenAddressess(true)}
          selectPaymentMethod={() => setOpenPaymentMethods(true)}
          onLoginOrRegister={() => setOpenLoginOrRegister(true)}
          onCheckout={(order) => {
            setOpenCart(false);
            setOrder(order);
          }}
        />
      )}

      {orderFlow !== Flows.DELIVERY && (
        <CartReceiveOn
          open={openCart}
          onClose={({ openCatalog } = {}) => {
            setOpenCart(false);
            if (openCatalog) {
              const newPath = Brand.alias
                ? `/${Brand.alias}/cardapio`
                : "/cardapio";
              router.push(newPath);
            }
          }}
        />
      )}

      <Club open={openClub} onClose={() => setOpenClub(false)} />

      <Profile open={openProfile} onClose={() => setOpenProfile(false)} />

      <PaymentMethods
        open={openPaymentMethods}
        onlyOnline={true}
        onClose={() => setOpenPaymentMethods(false)}
      />

      <Addressess
        open={openAddressess}
        onClose={() => setOpenAddressess(false)}
        alreadyDrawer={openCart}
      />

      <LoginOrRegister
        open={openLoginOrRegister}
        onClose={() => setOpenLoginOrRegister(false)}
        setIsLogged={() => loadUser()}
      />

      <OrderDetails
        open={openOrderDetails}
        onClose={() => {
          setOpenOrderDetails(false);
          setOpenOrderTracking(false);
        }}
        selectedOrder={selectedOrder}
        onTracking={() => setOpenOrderTracking(true)}
        alreadyDrawer
      />

      <OrderTracking
        open={openOrderTracking}
        onClose={() => setOpenOrderTracking(false)}
        selectedOrder={selectedOrder}
      />

      <OpenTable
        open={openCommand}
        onClose={() => setOpenCommand(false)}
        onCommandOpened={() => setCommandOpened(true)}
      />

      <ReceiveOnLocal
        open={openReceiveOn}
        onClose={() => setOpenReceiveOn(false)}
        inCatalog
      />

      <Drawer
        open={openConfirmBackToHome}
        alreadyDrawer
        direction="bottom-center"
      >
        <p className={styles.alertTitle}>Atenção</p>
        <p className={styles.alertSubtitle}>
          Ao trocar a mesa, seu carrinho será limpo e a mesa será perdida.
          Deseja continuar?
        </p>
        <Button
          design="primary"
          className="w-100 mb-10"
          onClick={() => setOpenConfirmBackToHome(false)}
        >
          Cancelar
        </Button>
        <Button
          design="secondary"
          className="w-100"
          onClick={() => backToHome()}
        >
          Sim, desejo trocar
        </Button>
      </Drawer>
    </>
  );
}
