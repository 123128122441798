import { BucketsAPI } from "api/BucketAPI";
import { EnvironmentAPI } from "api/EnvironmentAPI";
import { BaseStorage } from "storage/BaseStorage";
import { Holding } from "./Holding";
import { OlgaCustomer } from "./OlgaCustomer";
import { Store } from "./Store";

const brand_key = 'of-brand';

export const BrandEvents = {
    BRAND_CHANGED: 'BRAND_CHANGED'
}

export class Brand {

    static set alias(alias) {

        if (!alias) {
            localStorage.removeItem(brand_key);
        } else {
            localStorage.setItem(brand_key, alias);
        }

        window.dispatchEvent(
            new CustomEvent(BrandEvents.BRAND_CHANGED, { detail: alias })
        )
        return alias;
    }

    static async install(alias) {
        const envConfigurations = await EnvironmentAPI.getS3Configurations(alias);
        const baseStorage = new BaseStorage({ key: alias });
        if (envConfigurations.keys?.customer_api_key) {
            localStorage.setItem('customerApiKey', envConfigurations.keys.customer_api_key);
        }

        baseStorage.setData(envConfigurations);

        await OlgaCustomer.install(alias)

        if (envConfigurations.unit) {
            await Promise.all(
                envConfigurations.unit.map(async (u) => {
                    return await Store.install(u.alias)
                })
            );
        }
    }

    static get alias() {
        return localStorage.getItem(brand_key);
    }

    static getBrand() {
        try {
            if (Brand.alias) {
                const brand = localStorage.getItem(Brand.alias);
                return brand ? JSON.parse(brand) : null;
            }
            return null;
        } catch (err) {
            return null;
        }
    }
    
    static getBrandByAlias(alias) {
        try {
            if (alias) {
                const brand = localStorage.getItem(alias);
                return brand ? JSON.parse(brand) : null;
            }
            return null;
        } catch (err) {
            return null;
        }
    }

    static get holdingId () {
        const brand = Brand.getBrand();
        return brand?.environment_id;
    }

    static get name() {
        const brand = Brand.getBrand();
        return brand?.name;
    }

    static get avatar() {
        const brand = Brand.getBrand();
        const avatar = brand?.avatar || "";

        if (avatar.startsWith("http")) {
            return avatar;
        } else if (!avatar) {
            return null
        }

        return `${process.env.NEXT_PUBLIC_CDN_ASSETS}/${avatar}`;
    }

    static async getNearByUnitsFromAddress(geolocation, brandAlias = null) {
        const units = await BucketsAPI.getNearbyUnits(geolocation, brandAlias || Brand.alias);
        if (units.length > 0) {
            return units[0]
        }
        return null;
    }

    static get helpdesk_contact() {
        const brand = Brand.getBrand();
        return brand?.helpdesk_contact || Holding.helpdesk_contact;
    }

    static get type() {
        const brand = Brand.getBrand();
        return brand.type || null;
    }

    static get socials() {
        const brand = Brand.getBrand();
        return brand?.socials || {};
    }

    static get payment_methods() {
        return {}
    }

    static get theme() {
        const brand = Brand.getBrand();
        return brand?.front_theme_super_app || Holding.theme
    }

    static get description() {
        const brand = Brand.getBrand();
        return brand?.metadata?.description || null;
    }

    static get keys() {
        const brand = Brand.getBrand();
        return brand?.keys || {};
    }
    
    static keysByAlias(alias) {
        const brand = Brand.getBrandByAlias(alias);
        return brand?.keys || {};
    }

    static get google_tag_manager_key() {
		const brand = Brand.getBrand();
	
		if (brand) {
		  if (brand?.google?.google_tag_manager_key) {
			return brand.google.google_tag_manager_key;
		  }
	
		  return null;
		}
	
		return null;
	}

    static getMobileBanners () {
        const theme = Brand.theme;
        const banners = theme?.covers?.mobile || [];
        return banners;
    }

    static getDesktopBanners () {
        const theme = Brand.theme;
        const banners = theme?.covers?.desktop || [];
        return banners;
    }

    static onChange(eventHandler) {
        window.addEventListener(BrandEvents.BRAND_CHANGED, eventHandler);
    }

    static offChange(eventHandler) {
        window.removeEventListener(BrandEvents.BRAND_CHANGED, eventHandler);
    }
}